.single-page {
  padding-top: 80px;
  padding-bottom: 80px;

  a {
    color: $primary;
  }

  &.spaced {
    padding-top: 160px;
  }

  &.small {
    padding-top: 40px;
  }
}

.post-item__info-author_image {
  width: 2rem;
  height: auto;
  margin-right: 0.5rem;
  object-fit: cover;
}

.single-page__content {
  min-height: calc(100vh - 396px);
  font-size: 1.125rem;
  line-height: 1.5;
  color: #484d60;

  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    + em {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #484d60;
    }

    @media screen and (max-width: $breakpoint-md) {
      margin: 0;
      transform: scale(1);
    }
  }

  ul {
    li {
      margin-bottom: 1rem;
    }
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 3rem;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }

  table {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    thead {
      td,
      th {
        background: #222b49;
        padding: 1rem;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5;
        color: #ffffff;
      }
    }

    tbody {
      th,
      td {
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #484d60;
      }

      tr {
        &:nth-child(even) {
          th,
          td {
            background: #f7f8ff;
          }
        }
      }
    }
  }

  blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #222b49;
    padding-left: 2rem;
    box-shadow: inset 6px 0 0 0 #c9cef6;
  }

  .simple-table + table {
    margin-top: 1rem;
    margin-bottom: 1rem;

    thead {
      td,
      th {
        background: #ffffff;
        color: #222b49;
        padding: 0 0.5rem;
        text-align: left !important;
      }
    }

    tbody {
      th,
      td {
        padding: 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    overflow-x: auto;
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-link {
  font-size: 1rem;
  color: #555dc0;
  margin-right: 1rem;
}

.code-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 3rem auto;
}

.code-row {
  display: inline-flex;
}

.code-column {
  flex: 1;
  width: 50%;
  border: 1px solid #484d60;

  .highlight {
    background: #ffffff;
    margin: 0;
    height: 100%;

    pre {
      height: 100%;
    }
  }

  &.header {
    border: none;
  }

  &.right {
    text-align: right;
  }
}