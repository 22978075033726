.footer {
  background-color: #222b49;
  padding-top: 32px;
  padding-bottom: 32px;

  .divider {
    border-bottom-color: #6a7086;
  }
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.copyright,
.footer-note,
.footer-link {
  font-size: 1rem;
  color: $white;
  margin: 0;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: darken($white, 20);
    }
  }
}

.footer-link {
  display: block;
  text-align: right;
  text-decoration: underline;

  &:hover {
    color: darken($white, 20);
  }
}

.footer-social-link {
  margin: 0 0.25rem;
  color: $white;

  svg {
    width: 28px;
    height: 28px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: darken($white, 20);
  }
}

@media screen and (max-width: $breakpoint-md) {
  .footer {
    text-align: center;
  }

  .footer-links {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .footer-row {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;

    .col-4 {
      max-width: 100%;
    }
  }

  .footer-link {
    text-align: center;
  }

  .footer-social-links {
    justify-content: center !important;
    margin-top: 1rem;
  }
}
