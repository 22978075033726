.share-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 2px solid #c8cbd3;
}

.share-section__title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: #6a7086;
  margin-bottom: 1.5rem;
}

.share-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-button {
  font-size: 1.75rem;
  color: #222b49;
  transition: all 0.2s ease;
  margin-right: 0.75rem;
  cursor: pointer;

  &:hover {
    color: #555DC0;
  }
}
