.dark-navigation .navbar {
  background-color: #222b49;
}

.navbar {
  padding: 0.5rem 0;

  &.open,
  &.dark,
  &.fixed {
    background-color: #222b49;
  }

  &.with-banner {
    flex-wrap: wrap;

    .container-fluid {
      padding-top: 0.5rem;
    }
  }
}

.navbar-toggler {
  outline: none !important;
}

.navbar-brand {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-md) {
    width: 180px;
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.navbar-nav {
  align-items: center;

  @media screen and (max-width: $breakpoint-md) {
    align-items: flex-start;
  }
}

.navbar-collapse.collapsing {
  background: #222b49;
}
.navbar-collapse.collapse.show,
.navbar-collapse.collapse.in {
  display: block;
  background: #222b49;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: $white;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 0.2s ease;

  &.nav-link-logo {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    line-height: 1rem;

    svg {
      width: 24px;
      height: 24px;
    }

    @media screen and (max-width: $breakpoint-md) {
      svg {
        width: 34px;
        height: 34px;
      }
    }
  }

  &:hover {
    color: darken($white, 20);

    svg path {
      fill: darken($white, 20) !important;
    }
  }
}

.navbar-social-links {
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-md) {
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
  }
}

.navbar-nav__items {
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    padding-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.navbar .search-form {
  margin: 0;
  padding: 0 1rem;

  .input-group {
    display: flex;
    align-items: center;
    padding-right: 24px;
    width: 32px;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 6px;
    // -webkit-transition: all 0.2s ease-in-out;
    // transition: all 0.2s ease-in-out;
  }

  .form-control,
  .form-control:focus {
    height: 2.25rem;
    padding: 6px 12px;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    background-color: transparent;
    border-color: transparent;
    color: #fff;

    &:-ms-input-placeholder {
      color: #999dad !important;
    }

    &::-moz-placeholder {
      color: #999dad !important;
    }

    &:-moz-placeholder {
      color: #999dad !important;
    }

    &::-webkit-input-placeholder {
      color: #999dad !important;
    }
  }

  .input-group-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
    height: 2.25rem;
    z-index: 2;
  }

  .btn {
    height: auto;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid transparent;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #fff;

    &:active,
    &:hover,
    &:focus {
      color: #c9cef6;
      background-color: transparent;
      border: 1px solid transparent;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      outline: none;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0;
    }
  }

  &.open {
    .input-group {
      position: absolute;
      right: 1rem;
      top: 0;
      width: 120%;
      background: #222b49;
      border: 1px solid #ffffff;
    }

    .input-group-btn {
      width: 2rem;
    }
  }
}

@media (max-width: 767px) {
  .navbar .search-form,
  .navbar .search-form.open {
    margin: 1rem 0;
    width: 100%;

    .input-group {
      position: relative;
      right: auto;
      width: 100%;
      top: auto;
      background: #222b49;
      border: 1px solid #ffffff;

      .form-control,
      .input-group-btn {
        height: 2.75rem;
      }
    }

    .btn {
      -webkit-transition: none;
      transition: none;
    }
  }
}

.banner {
  background: #555dc0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  margin-top: -0.5rem;

  * {
    font-weight: 800;
    font-size: 1.125rem;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: #ffffff;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}
