.btn-icon {
  padding: 14px 40px;
  height: 48px;

  svg {
    width: 1.4em;
    height: auto;
    margin-right: 1em;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 14px;

    svg {
      margin: 0;
    }

    span:last-of-type {
      display: none;
    }
  }
}

.btn-twitter {
  display: inline-flex;
  align-items: center;
  background: #1da1f2;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    background: darken(#1da1f2, 5);
  }
}

.btn-mail,
.btn-discord {
  display: inline-flex;
  align-items: center;
  background: #7289da;
  font-weight: bold;
  font-size: 0.875em;
  line-height: 1em;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;

  svg {
    width: 2em;
  }

  &:hover {
    color: #ffffff;
    background: darken(#7289da, 5);
  }
}

.btn-github {
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  font-weight: bold;
  font-size: 0.875em;
  line-height: 1em;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #24292e;

  svg {
    width: 2em;
  }

  &:hover {
    color: #24292e;
    background: darken(#ffffff, 5);
  }
}

.btn-link {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #555dc0;

  svg {
    margin-right: 1rem;
  }

  &.btn-link-back {
    padding-top: 1rem;
    padding-bottom: 0;
  }
}

.donwload-link {
  color: $white;
  display: flex;
  align-items: center;

  &:hover {
    color: darken($white, 20);
    text-decoration: none;
  }
}

.btn-more {
  padding: 12px 34px;
}

.btn-white {
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #555dc0;

  &:hover {
    color: #555dc0;
  }
}

.hero-btn.btn-github {
  font-size: 1.125em;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: 0.04em;
}

.btn-banner {
  display: inline-flex;
  align-items: center;
  background: transparent;
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.5em;
  border: 2px solid #ffffff;
  border-radius: 6px;
  color: #ffffff;

  &:hover {
    background-color: #ffffff;
    color: #555dc0;
  }
}

.btn-primary,
.btn-purple {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  border-color: #555dc0;
  background: #555dc0;
  border-radius: 6px;
  padding: 12px 24px;
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1em;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    background: darken(#555dc0, 10%);
  }
}
