$primary: #555DC0;
$white: #ffffff;
$black: #000000;
$code-color: #222B49;

$modal-backdrop-bg:           #222B49;
$modal-backdrop-opacity:      .5;


$breakpoint-sm:   576px;
$breakpoint-md:   768px;
$breakpoint-lg:   992px;
$breakpoint-xl:   1200px;
