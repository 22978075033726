.results-message {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #484d60;
  margin-bottom: 50px;
}

.search-results .search-form {
  display: flex;

  .form-control {
    border-radius: 0;
    border-right: 0;
    box-shadow: none;
  }

  .btn {
    border-radius: 0;
    border: 1px solid #ced4da;
    line-height: 1em;
  }
}

.post-item__doc-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span:not(:last-of-type) {
    display: flex;
    align-items: center;

    &::after {
      content: "❯";
      padding: 0 0.5rem;
      color: #c9cef6;
      font-size: 1rem;
    }
  }
}
