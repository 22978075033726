// support
@import "./support/base";
@import "./support/container";

// components
@import "./components/buttons";
@import "./components/pills";
@import "./components/hero";
@import "./components/navigation";
@import "./components/footer";
@import "./components/syntax-highlighting";
@import "./components/share";
@import "./components/modal";
@import "./components/cookies";
@import "./components/newsletter";

// pages

// @import "./pages/index";
@import "./pages/homepage";
@import "./pages/blog";
@import "./pages/post";
@import "./pages/results";
