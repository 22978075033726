.newsletter-card {
  padding: 80px 190px 80px 110px;
  background-color: #222b49;
  background-image: url($baseurl + "/assets/images/newsletter-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;

  .heading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-right: 40px;

    h2 {
      font-weight: 800;
      font-size: 2.25rem;
      line-height: 1.2em;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: auto;
    }
  }

  .content-form {
    display: flex;
    flex-direction: column;
    width: 45%;

    form {
      width: 100%;
      margin-bottom: 40px;
    }

    label {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2em;
      color: #ffffff;
    }

    button {
      margin-top: 16px;
      border-radius: 6px;
      width: 100%;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.56em;
      text-align: center;
      letter-spacing: 0.04em;
      color: #ffffff;
    }

    .newsletter-form-message {
      color: #ffffff;
    }
  }


  @media screen and (max-width: $breakpoint-md) {
    background-image: url($baseurl + "/assets/images/mobile-newsletter-bg.png");
    flex-direction: column;
    padding: 36px 30px;

    .heading-content,
    .content-form {
      width: 100%;
      padding: 0;

      h2 {
        font-size: 1.5rem;
        line-height: 1.2em;
        margin-bottom: 32px;
      }

      img {
        display: none;
      }
    }
  }
}
