.hero-page {
  background-color: #222b49;
  background-image: url($baseurl + "/assets/images/hero-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 80px;

  @media screen and (max-width: $breakpoint-md) {
    background-image: url($baseurl + "/assets/images/mobile-hero-bg.png");
  }
}

.hero-page__title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.3;
  color: #ffffff;

  @media screen and (max-width: $breakpoint-md) {
    text-align: center;
  }
}

.social-links {
  display: flex;
  margin-top: 2rem;

  .btn {
    margin: 0 0.5rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    justify-content: center;
  }
}

body.includes-banner div[class^="hero-"],
body.includes-banner header[class^="hero-"] {
  padding-top: 160px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 240px;
  }
}
