.homepage {
  background: #222b49 url($baseurl + "/assets/images/homepage-bg.png") no-repeat;
  background-size: cover;
  padding-bottom: 96px;

  section:not(:last-of-type) {
    margin-bottom: 96px;
  }

  &__hero {
    padding-top: 162px;

    &--title {
      font-family: "Nunito Sans";
      font-weight: 900;
      font-size: 3rem;
      line-height: 1.2em;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 1.3125rem;
    }

    &--subtitle {
      font-family: "Nunito Sans";
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
      margin-bottom: 36px;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &--buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      .btn-purple {
        display: inline-flex;
        align-items: center;
        margin-bottom: 1.5em;

        svg {
          margin-left: 0.25em;
          margin-right: 1.5em;
        }

        &:not(:last-of-type) {
          margin-right: 1.5em;
        }
      }

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;

        .btn-purple {
          &:not(:last-of-type) {
            margin-right: 0;
          }
        }
      }
    }

    &--extra-links {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 1rem;
        line-height: 1em;
        text-align: center;
        text-decoration-line: underline;
        color: #ffffff;

        &:not(:last-of-type) {
          margin-right: 1.5rem;
        }
      }

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;

        a {
          &:not(:last-of-type) {
            margin-right: 0;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    .row {
      justify-content: center;
    }
  }

  &__stats {
    .stats {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      &.hidden {
        display: none;
      }

      .stat {
        display: flex;
        margin: 0.5rem;
        color: #3b3b3b;

        span {
          font-family: "Nunito Sans";
          font-weight: 700;
          font-size: 0.75rem;
          line-height: 1.3em;
          text-transform: capitalize;
        }

        &__info {
          display: flex;
          align-items: center;
          background: #f6f6f6;
          border: 1px solid #c8cbd3;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          padding: 6px 8px;

          svg {
            margin-right: 0.25rem;
          }
        }

        &__data {
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 1px solid #c8cbd3;
          border-left-width: 0;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 6px 8px;
        }
      }

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
      }
    }
  }

  &__banner {
    background: #555dc0;
    padding: 28px 0;

    .row {
      align-items: center;
    }

    .btn {
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
      border: 2px solid #ffffff;
      border-radius: 6px;
      padding: 12px 0;
      font-family: "Nunito Sans";
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1em;
      text-align: center;
      letter-spacing: 0.04em;
      color: #ffffff;

      &:hover {
        color: #555dc0;
      }
    }

    h4 {
      font-family: "Nunito Sans";
      font-weight: 800;
      font-size: 1.3125rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0;

      @media screen and (max-width: $breakpoint-md) {
        margin-bottom: 24px;
      }
    }
  }

  &__blog {
    &--title {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.5em;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 32px;
    }

    .blog-item {
      background: #222b49;
      border-radius: 6px;
      padding: 30px;
      margin-bottom: 32px;
      text-decoration: none;
      width: 100%;

      p {
        font-family: "Nunito Sans";
        font-weight: 800;
        font-size: 1rem;
        line-height: 1.5em;
        color: #ffffff;
        margin-bottom: 0.75rem;
      }

      &__info {
        display: flex;
        align-items: baseline;
        font-family: "Mulish";
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5em;
        color: #ffffff;

        &-author {
          color: #c9cef6;
          font-weight: 700;
        }
      }
    }
  }

  &__release {
    .github-releases {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: capitalize;
      color: #ffffff;
      margin: 0;

      span {
        color: #c9cef6;
        font-weight: 700;
      }

      a {
        color: #c9cef6;
      }

      &.hidden {
        display: none;
      }
    }
  }

  &__video {
    &--title {
      font-family: "Nunito Sans";
      font-weight: 800;
      font-size: 2.25rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
    }

    &--text {
      font-family: "Nunito Sans";
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
    }

    &--player {
      position: relative;
      display: block;
      margin-top: 2.75rem;
      cursor: pointer;

      .play-icon {
        font-size: 5rem;
        color: #555dc0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }

      &__image {
        width: 100%;
        z-index: 1;
      }

      &::after {
        content: "";
        background: #222b49;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.4s ease;
      }

      &:hover {
        &::after {
          opacity: 0.3;
        }
      }
    }
  }

  &__tabs {
    h2 {
      font-family: "Nunito Sans";
      font-weight: 800;
      font-size: 2.25rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
    }

    .nav-pills--wrapper {
      width: 100%;
      height: 40px;
      overflow: hidden;
      margin-top: 64px;
      margin-bottom: 44px;

      .nav-pills {
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 24px;
        box-sizing: content-box;
        justify-content: flex-start;
        flex-wrap: nowrap;

        .nav-link {
          white-space: nowrap;
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        .nav-pills {
          justify-content: center;
        }
      }
    }

    .tab__card {
      display: flex;
      background: #222b49;
      border-radius: 6px;
      padding: 32px;
      margin-bottom: 24px;

      &-body {
        font-family: "Roboto";
        font-size: 1rem;
        line-height: 1.5em;
        color: #ffffff;

        a {
          font-weight: 500;
          text-decoration: underline;
          color: #ffffff;
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        height: 100%;
        margin-bottom: 0;
      }
    }
  }

  &__extensions {
    &--header {
      margin-bottom: 44px;

      h2 {
        font-family: "Nunito Sans";
        font-weight: 800;
        font-size: 2.25rem;
        line-height: 1.5em;
        text-align: center;
        color: #ffffff;
        margin-bottom: 1rem;
      }

      p {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5em;
        text-align: center;
        color: #ffffff;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--grid {
      display: grid;
      grid-row-gap: 16px;

      .grid--title {
        padding: 6px 0;
        background: #c9cef6;
        border-color: #c9cef6;
        border-radius: 16px;
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.5em;
        text-align: center;
        color: #222b49;
        width: 100%;
        margin: 24px 0 0;
      }

      .grid--item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        padding: 24px;
        background: #ffffff;

        img {
          margin-bottom: 1rem;
        }

        p {
          font-family: "Nunito Sans";
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5em;
          text-align: center;
          color: #555dc0;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 36px;

        .grid--title {
          grid-column: 1 / 2;
          grid-row: 1 / span 1;
          margin-top: 0;

          &.large {
            grid-column: 2 / 6;
          }
        }
      }
    }

    &--arrow {
      width: 100%;
      height: auto;
      margin-top: 44px;
    }

    &--arrow-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      span {
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.5em;
        color: #c9cef6;
      }
    }
  }

  &__architecture {
    h2 {
      font-family: "Nunito Sans";
      font-weight: 800;
      font-size: 2.25rem;
      line-height: 1.5em;
      text-align: center;
      color: #ffffff;
      margin-bottom: 44px;
    }
  }

  &__scale {
    h3 {
      font-family: "Nunito Sans";
      font-size: 1.875rem;
      line-height: 1.5em;
      color: #ffffff;
      padding-right: 16px;
    }

    &--text {
      font-family: "Nunito Sans";
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5em;
      color: #ffffff;

      h4 {
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #c9cef6;
        margin-bottom: 1rem;
      }

      ul {
        margin-bottom: 2.75rem;
      }
    }
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
